import * as React from "react"
import { FC } from "react"
import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { navigate } from "gatsby"

const { useEffect } = React

interface Props {
    location: any
}

export const Complete: React.FC<Props> = ({ location }) => {
    useEffect(() => {
        if (!location?.state?.toAddress) {
            navigate("/")
        }
    }, [])

    return (
        <GlobalLayout Head={<Head title="send" noIndex />}>
            <section className="p-section u-mb-100px">
                {location?.state?.toAddress && (
                    <>
                        <div
                            className={
                                "w-3/4 rsm:w-11/12 mr-auto ml-auto mt-14 text-base leading-none"
                            }
                        >
                            <ContactTemplate
                                toAddress={location.state.toAddress}
                            />
                        </div>
                    </>
                )}
            </section>
        </GlobalLayout>
    )
}

type ContactTemplate = {
    toAddress: string
}
const ContactTemplate: FC<ContactTemplate> = ({ toAddress }) => (
    <>
        <p className={"u-mb-10"}>お問合せいただき誠にありがとうございます。</p>
        <p className={"u-mb-10"}>
            改めてお問合せ内容を下記メールアドレスに送信いたしますので、ご確認をお願いします。
            <br />
            <span className={"text-xs text-gray-500"}>
                ※10分以内に自動送信されます。
            </span>
        </p>
        <code className={"bg-gray-100 pt-4 pb-4 pl-2 block rounded-sm"}>
            {toAddress}
        </code>
    </>
)
